<template>
  <div class="flex flex-col animate-pulse">
    <div
      :class="[{'rounded-xl' : card }, {'rounded-l-xxl' : cardsmall },'border border-neutral-100 px-5 py-5 flex flex-col gap-3 flex h-full']"
    >
      <div class="bg-neutral-50 w-[46px] h-[46px]" />
      <div class="bg-neutral-50 w-full h-[1.5rem] rounded-full" />
      <div class="flex flex-col gap-2">
        <div class="bg-neutral-50 w-full h-[1.125rem] rounded-full" />
        <div class="bg-neutral-50 w-full h-[1.125rem] rounded-full" />
        <div class="bg-neutral-50 w-full h-[1.125rem] rounded-full" />
        <div class="bg-neutral-50 w-[50%] h-[1.125rem] rounded-full" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

const props = defineProps({
  design: {
    type: String,
    default: 'card'
  }
})

const card = computed(() => props.design === 'card')
const cardsmall = computed(() => props.design === 'cardsmall')
</script>

<style lang="scss">
.skeleton {
  @apply bg-neutral-100;
}
</style>
